<template>
    <a-card :bordered="false" class="card-area">
        <div class="search"><!-- 搜索区域 -->
            <div class="items" style="flex: none">
                <div class="one-item">
                    <a-select v-model="value" placeholder="选择过滤字段" style="width:100%">
                        <a-select-option value="公司名称">公司名称</a-select-option>
                        <a-select-option value="统一代码">统一代码</a-select-option>
                        <a-select-option value="法人">法人</a-select-option>
                    </a-select>
                </div>
                <div class="one-item">
                    <a-input placeholder="请输入筛选条件"></a-input>
                </div>
            </div>
            <div class="operator">
                <a-button type="primary" @click="btsearch">查询</a-button>
            </div>
        </div>
        <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
            :scroll="{ x: 1000 }">
            <template slot="imghead" slot-scope="text">
                <div v-if="text.uhead" class="img-list">
                    <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                        :preview-src-list="(text.uhead || '').split(',')" />
                </div>
            </template>
            <template slot="status" slot-scope="text,record">
                <a-tag :color="record.status | dictName('payRequestStatus', 'color')">
                    {{ record.status | dictName("payRequestStatus") }}
                </a-tag>
            </template>
        </a-table>
    </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";
import { message } from 'ant-design-vue';
export default {
    name: "commercialList",
    data() {
        return {
            tableData: [],
            value: ''
        };
    },
    created() {
        this.searchTable();
    },
    computed: {
        payRequestStatus() {
            return DICT['payRequestStatus']
        },
        columns() {
            return [{
                title: "ID",
                width: 50,
                align: "center",
                dataIndex: "id",
            }, {
                title: "公司名称",
                width: 135,
                align: "center",
                dataIndex: "company",
            },{
                title: "英文名称",
                width: 80,
                align: "center",
                dataIndex: "enName",
            },{
                title: "统一社会信用代码",
                width: 120,
                align: "center",
                dataIndex: "bizCreditNo",
            }, {
                title: "企业类型",
                width: 80,
                align: "center",
                dataIndex: "ctype",
            }, {
                title: "经营状况",
                width: 80,
                align: "center",
                dataIndex: "cstatus",
            }, {
                title: "成立日期",
                width: 80,
                align: "center",
                dataIndex: "cldate",
            }, {
                title: "核准日期",
                width: 80,
                align: "center",
                dataIndex: "hzdate",
            }, {
                title: "法定代表人",
                width: 80,
                align: "center",
                dataIndex: "legalPerson",
            },{
                title: "注册资本",
                width: 80,
                align: "center",
                dataIndex: "regamg",
            },{
                title: "实缴资本",
                width: 80,
                align: "center",
                dataIndex: "sjamg",
            },{
                title: "参保人数",
                width: 80,
                align: "center",
                dataIndex: "cbqty",
            },{
                title: "公司规模",
                width: 80,
                align: "center",
                dataIndex: "gsdes",
            },{
                title: "经营范围",
                width: 500,
                align: "center",
                dataIndex: "bizScope",
            },{
                title: "注册地址",
                width: 80,
                align: "center",
                dataIndex: "zcadr",
            },{
                title: "营业期限",
                width: 80,
                align: "center",
                dataIndex: "yedate",
            },{
                title: "纳税人识别号",
                width: 80,
                align: "center",
                dataIndex: "nsrnum",
            },{
                title: "工商注册号",
                width: 80,
                align: "center",
                dataIndex: "gsnum",
            },{
                title: "组织机构代码",
                width: 80,
                align: "center",
                dataIndex: "orgnum",
            },{
                title: "联系电话",
                width: 80,
                align: "center",
                dataIndex: "cphone",
            },{
                title: "邮箱",
                width: 80,
                align: "center",
                dataIndex: "ceml",
            },{
                title: "纳税人资质",
                width: 80,
                align: "center",
                dataIndex: "nszz",
            },{
                title: "曾用名",
                width: 80,
                align: "center",
                dataIndex: "olduname",
            },{
                title: "所属省份",
                width: 80,
                align: "center",
                dataIndex: "province",
            },{
                title: "所属城市",
                width: 80,
                align: "center",
                dataIndex: "city",
            },{
                title: "所属区县",
                width: 80,
                align: "center",
                dataIndex: "area",
            },{
                title: "网站链接",
                width: 80,
                align: "center",
                dataIndex: "cwebsite",
            },{
                title: "所属行业",
                width: 80,
                align: "center",
                dataIndex: "industry",
            } ,{
                title: "一级行业分类",
                width: 80,
                align: "center",
                dataIndex: "industry1",
            } ,{
                title: "二级行业分类",
                width: 80,
                align: "center",
                dataIndex: "industry2",
            } ,{
                title: "三级行业分类",
                width: 80,
                align: "center",
                dataIndex: "industry3",
            } ,{
                title: "登记机关",
                width: 80,
                align: "center",
                dataIndex: "djorg",
            } ,{
                title: "经度",
                width: 80,
                align: "center",
                dataIndex: "jd",
            } ,{
                title: "纬度",
                width: 80,
                align: "center",
                dataIndex: "wd",
            }  ];
        }
    },
    methods: {
        btsearch() {
            message.error('查询功能待开发');
        },
        searchTable() {
            let that = this;
            that.showLoading = true;
            that.$get("companyGS/CompanyGSList", {}).then((res) => {
                that.showLoading = false;
                console.log(res.data);
                that.tableData = res.data;
            });
        }
    }
}
</script>
<style>
.el-row {
    margin-bottom: 20px;
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}
</style>
